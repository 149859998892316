package strings

import io.github.skeptick.libres.strings.PluralForms
import io.github.skeptick.libres.strings.getCurrentLanguageCode
import kotlin.String
import kotlin.collections.Map

public object ResStrings {
  private val baseLocale: StringsEs = StringsEs

  private val locales: Map<String, Strings> = mapOf("en" to StringsEn, "es" to StringsEs)

  public val locale: String
    get() = locales[getCurrentLanguageCode()]?.locale ?: baseLocale.locale

  public val language_option_es: String
    get() = locales[getCurrentLanguageCode()]?.language_option_es ?: baseLocale.language_option_es

  public val language_option_en: String
    get() = locales[getCurrentLanguageCode()]?.language_option_en ?: baseLocale.language_option_en

  public val site_metatag_title: String
    get() = locales[getCurrentLanguageCode()]?.site_metatag_title ?: baseLocale.site_metatag_title

  public val site_metatag_description: String
    get() = locales[getCurrentLanguageCode()]?.site_metatag_description ?:
        baseLocale.site_metatag_description

  public val site_navlink_about_us: String
    get() = locales[getCurrentLanguageCode()]?.site_navlink_about_us ?:
        baseLocale.site_navlink_about_us

  public val site_navlink_resources: String
    get() = locales[getCurrentLanguageCode()]?.site_navlink_resources ?:
        baseLocale.site_navlink_resources

  public val site_navlink_conference: String
    get() = locales[getCurrentLanguageCode()]?.site_navlink_conference ?:
        baseLocale.site_navlink_conference

  public val site_navlink_subscribe: String
    get() = locales[getCurrentLanguageCode()]?.site_navlink_subscribe ?:
        baseLocale.site_navlink_subscribe

  public val home_header_title: String
    get() = locales[getCurrentLanguageCode()]?.home_header_title ?: baseLocale.home_header_title

  public val home_header_caption: String
    get() = locales[getCurrentLanguageCode()]?.home_header_caption ?: baseLocale.home_header_caption

  public val home_quick_links_wsp: String
    get() = locales[getCurrentLanguageCode()]?.home_quick_links_wsp ?:
        baseLocale.home_quick_links_wsp

  public val home_quick_links_talk: String
    get() = locales[getCurrentLanguageCode()]?.home_quick_links_talk ?:
        baseLocale.home_quick_links_talk

  public val home_quick_links_linkedin: String
    get() = locales[getCurrentLanguageCode()]?.home_quick_links_linkedin ?:
        baseLocale.home_quick_links_linkedin

  public val home_highlights_card_title: String
    get() = locales[getCurrentLanguageCode()]?.home_highlights_card_title ?:
        baseLocale.home_highlights_card_title

  public val home_highlights_card_kotlin_conf_title: String
    get() = locales[getCurrentLanguageCode()]?.home_highlights_card_kotlin_conf_title ?:
        baseLocale.home_highlights_card_kotlin_conf_title

  public val home_highlights_card_kotlin_conf_description: String
    get() = locales[getCurrentLanguageCode()]?.home_highlights_card_kotlin_conf_description ?:
        baseLocale.home_highlights_card_kotlin_conf_description

  public val home_highlights_card_kotlin_conf_cta: String
    get() = locales[getCurrentLanguageCode()]?.home_highlights_card_kotlin_conf_cta ?:
        baseLocale.home_highlights_card_kotlin_conf_cta

  public val home_highlights_card_salary_2023_title: String
    get() = locales[getCurrentLanguageCode()]?.home_highlights_card_salary_2023_title ?:
        baseLocale.home_highlights_card_salary_2023_title

  public val home_highlights_card_salary_2023_description: String
    get() = locales[getCurrentLanguageCode()]?.home_highlights_card_salary_2023_description ?:
        baseLocale.home_highlights_card_salary_2023_description

  public val home_highlights_card_salary_2023_cta: String
    get() = locales[getCurrentLanguageCode()]?.home_highlights_card_salary_2023_cta ?:
        baseLocale.home_highlights_card_salary_2023_cta

  public val home_save_the_date: String
    get() = locales[getCurrentLanguageCode()]?.home_save_the_date ?: baseLocale.home_save_the_date

  public val home_save_the_date_location: String
    get() = locales[getCurrentLanguageCode()]?.home_save_the_date_location ?:
        baseLocale.home_save_the_date_location

  public val about_us_title: String
    get() = locales[getCurrentLanguageCode()]?.about_us_title ?: baseLocale.about_us_title

  public val about_us_description: String
    get() = locales[getCurrentLanguageCode()]?.about_us_description ?:
        baseLocale.about_us_description

  public val about_organizers_title: String
    get() = locales[getCurrentLanguageCode()]?.about_organizers_title ?:
        baseLocale.about_organizers_title

  public val about_organizers_caption: String
    get() = locales[getCurrentLanguageCode()]?.about_organizers_caption ?:
        baseLocale.about_organizers_caption

  public val about_ex_organizers_title: String
    get() = locales[getCurrentLanguageCode()]?.about_ex_organizers_title ?:
        baseLocale.about_ex_organizers_title

  public val about_ex_organizers_caption: String
    get() = locales[getCurrentLanguageCode()]?.about_ex_organizers_caption ?:
        baseLocale.about_ex_organizers_caption

  public val resources_blog_posts_title: String
    get() = locales[getCurrentLanguageCode()]?.resources_blog_posts_title ?:
        baseLocale.resources_blog_posts_title

  public val resources_blog_posts_description: String
    get() = locales[getCurrentLanguageCode()]?.resources_blog_posts_description ?:
        baseLocale.resources_blog_posts_description

  public val resources_blog_posts_cta: String
    get() = locales[getCurrentLanguageCode()]?.resources_blog_posts_cta ?:
        baseLocale.resources_blog_posts_cta

  public val resources_blog_posts_caption: String
    get() = locales[getCurrentLanguageCode()]?.resources_blog_posts_caption ?:
        baseLocale.resources_blog_posts_caption

  public val resources_blog_posts_meetup_57: String
    get() = locales[getCurrentLanguageCode()]?.resources_blog_posts_meetup_57 ?:
        baseLocale.resources_blog_posts_meetup_57

  public val resources_blog_posts_meetup_57_desc: String
    get() = locales[getCurrentLanguageCode()]?.resources_blog_posts_meetup_57_desc ?:
        baseLocale.resources_blog_posts_meetup_57_desc

  public val resources_blog_posts_devfest_2023: String
    get() = locales[getCurrentLanguageCode()]?.resources_blog_posts_devfest_2023 ?:
        baseLocale.resources_blog_posts_devfest_2023

  public val resources_blog_posts_devfest_2023_desc: String
    get() = locales[getCurrentLanguageCode()]?.resources_blog_posts_devfest_2023_desc ?:
        baseLocale.resources_blog_posts_devfest_2023_desc

  public val resources_blog_posts_bcp: String
    get() = locales[getCurrentLanguageCode()]?.resources_blog_posts_bcp ?:
        baseLocale.resources_blog_posts_bcp

  public val resources_blog_posts_bcp_desc: String
    get() = locales[getCurrentLanguageCode()]?.resources_blog_posts_bcp_desc ?:
        baseLocale.resources_blog_posts_bcp_desc

  public val resources_blog_posts_meetup_56: String
    get() = locales[getCurrentLanguageCode()]?.resources_blog_posts_meetup_56 ?:
        baseLocale.resources_blog_posts_meetup_56

  public val resources_blog_posts_meetup_56_desc: String
    get() = locales[getCurrentLanguageCode()]?.resources_blog_posts_meetup_56_desc ?:
        baseLocale.resources_blog_posts_meetup_56_desc

  public val resources_playlists_title: String
    get() = locales[getCurrentLanguageCode()]?.resources_playlists_title ?:
        baseLocale.resources_playlists_title

  public val resources_playlists_description: String
    get() = locales[getCurrentLanguageCode()]?.resources_playlists_description ?:
        baseLocale.resources_playlists_description

  public val resources_playlists_cta: String
    get() = locales[getCurrentLanguageCode()]?.resources_playlists_cta ?:
        baseLocale.resources_playlists_cta

  public val resources_playlists_caption: String
    get() = locales[getCurrentLanguageCode()]?.resources_playlists_caption ?:
        baseLocale.resources_playlists_caption

  public val resources_playlists_card_meetups: String
    get() = locales[getCurrentLanguageCode()]?.resources_playlists_card_meetups ?:
        baseLocale.resources_playlists_card_meetups

  public val resources_playlists_card_meetups_desc: String
    get() = locales[getCurrentLanguageCode()]?.resources_playlists_card_meetups_desc ?:
        baseLocale.resources_playlists_card_meetups_desc

  public val resources_playlists_card_android_workshop: String
    get() = locales[getCurrentLanguageCode()]?.resources_playlists_card_android_workshop ?:
        baseLocale.resources_playlists_card_android_workshop

  public val resources_playlists_card_android_workshop_desc: String
    get() = locales[getCurrentLanguageCode()]?.resources_playlists_card_android_workshop_desc ?:
        baseLocale.resources_playlists_card_android_workshop_desc

  public val main_footer_subscribe: String
    get() = locales[getCurrentLanguageCode()]?.main_footer_subscribe ?:
        baseLocale.main_footer_subscribe

  public val main_footer_this_web_is: String
    get() = locales[getCurrentLanguageCode()]?.main_footer_this_web_is ?:
        baseLocale.main_footer_this_web_is

  public val main_footer_open_source: String
    get() = locales[getCurrentLanguageCode()]?.main_footer_open_source ?:
        baseLocale.main_footer_open_source

  public val conf_title: String
    get() = locales[getCurrentLanguageCode()]?.conf_title ?: baseLocale.conf_title

  public val conf_meta_title: String
    get() = locales[getCurrentLanguageCode()]?.conf_meta_title ?: baseLocale.conf_meta_title

  public val conf_meta_description: String
    get() = locales[getCurrentLanguageCode()]?.conf_meta_description ?:
        baseLocale.conf_meta_description

  public val conf_navlink_c4p: String
    get() = locales[getCurrentLanguageCode()]?.conf_navlink_c4p ?: baseLocale.conf_navlink_c4p

  public val conf_navlink_sponsor: String
    get() = locales[getCurrentLanguageCode()]?.conf_navlink_sponsor ?:
        baseLocale.conf_navlink_sponsor

  public val conf_navlink_about_us: String
    get() = locales[getCurrentLanguageCode()]?.conf_navlink_about_us ?:
        baseLocale.conf_navlink_about_us

  public val conf_navlink_register: String
    get() = locales[getCurrentLanguageCode()]?.conf_navlink_register ?:
        baseLocale.conf_navlink_register

  public val conf_navlink_code_of_conduct: String
    get() = locales[getCurrentLanguageCode()]?.conf_navlink_code_of_conduct ?:
        baseLocale.conf_navlink_code_of_conduct

  public val conf_header_time: String
    get() = locales[getCurrentLanguageCode()]?.conf_header_time ?: baseLocale.conf_header_time

  public val conf_header_location: String
    get() = locales[getCurrentLanguageCode()]?.conf_header_location ?:
        baseLocale.conf_header_location

  public val conf_header_caption: String
    get() = locales[getCurrentLanguageCode()]?.conf_header_caption ?: baseLocale.conf_header_caption

  public val conf_events_title: String
    get() = locales[getCurrentLanguageCode()]?.conf_events_title ?: baseLocale.conf_events_title

  public val conf_events_celebrate: String
    get() = locales[getCurrentLanguageCode()]?.conf_events_celebrate ?:
        baseLocale.conf_events_celebrate

  public val conf_events_enjoy: String
    get() = locales[getCurrentLanguageCode()]?.conf_events_enjoy ?: baseLocale.conf_events_enjoy

  public val conf_events_free: String
    get() = locales[getCurrentLanguageCode()]?.conf_events_free ?: baseLocale.conf_events_free

  public val conf_events_registration_soon: String
    get() = locales[getCurrentLanguageCode()]?.conf_events_registration_soon ?:
        baseLocale.conf_events_registration_soon

  public val conf_events_register: String
    get() = locales[getCurrentLanguageCode()]?.conf_events_register ?:
        baseLocale.conf_events_register

  public val conf_c4p_title: String
    get() = locales[getCurrentLanguageCode()]?.conf_c4p_title ?: baseLocale.conf_c4p_title

  public val conf_c4p_caption: String
    get() = locales[getCurrentLanguageCode()]?.conf_c4p_caption ?: baseLocale.conf_c4p_caption

  public val conf_c4p_cta: String
    get() = locales[getCurrentLanguageCode()]?.conf_c4p_cta ?: baseLocale.conf_c4p_cta

  public val conf_location_title: String
    get() = locales[getCurrentLanguageCode()]?.conf_location_title ?: baseLocale.conf_location_title

  public val conf_location_details: String
    get() = locales[getCurrentLanguageCode()]?.conf_location_details ?:
        baseLocale.conf_location_details

  public val conf_location_cta: String
    get() = locales[getCurrentLanguageCode()]?.conf_location_cta ?: baseLocale.conf_location_cta

  public val conf_location_map_alt: String
    get() = locales[getCurrentLanguageCode()]?.conf_location_map_alt ?:
        baseLocale.conf_location_map_alt

  public val conf_sponsor_title: String
    get() = locales[getCurrentLanguageCode()]?.conf_sponsor_title ?: baseLocale.conf_sponsor_title

  public val conf_sponsor_caption: String
    get() = locales[getCurrentLanguageCode()]?.conf_sponsor_caption ?:
        baseLocale.conf_sponsor_caption

  public val conf_sponsor_im_interested: String
    get() = locales[getCurrentLanguageCode()]?.conf_sponsor_im_interested ?:
        baseLocale.conf_sponsor_im_interested

  public val conf_sponsorship_title: String
    get() = locales[getCurrentLanguageCode()]?.conf_sponsorship_title ?:
        baseLocale.conf_sponsorship_title

  public val conf_sponsorship_p1: String
    get() = locales[getCurrentLanguageCode()]?.conf_sponsorship_p1 ?: baseLocale.conf_sponsorship_p1

  public val conf_sponsorship_p2: String
    get() = locales[getCurrentLanguageCode()]?.conf_sponsorship_p2 ?: baseLocale.conf_sponsorship_p2

  public val conf_sponsorship_p2_li1: String
    get() = locales[getCurrentLanguageCode()]?.conf_sponsorship_p2_li1 ?:
        baseLocale.conf_sponsorship_p2_li1

  public val conf_sponsorship_p2_li1_1: String
    get() = locales[getCurrentLanguageCode()]?.conf_sponsorship_p2_li1_1 ?:
        baseLocale.conf_sponsorship_p2_li1_1

  public val conf_sponsorship_p2_li1_2: String
    get() = locales[getCurrentLanguageCode()]?.conf_sponsorship_p2_li1_2 ?:
        baseLocale.conf_sponsorship_p2_li1_2

  public val conf_sponsorship_p2_li1_3: String
    get() = locales[getCurrentLanguageCode()]?.conf_sponsorship_p2_li1_3 ?:
        baseLocale.conf_sponsorship_p2_li1_3

  public val conf_sponsorship_p2_li1_4: String
    get() = locales[getCurrentLanguageCode()]?.conf_sponsorship_p2_li1_4 ?:
        baseLocale.conf_sponsorship_p2_li1_4

  public val conf_sponsorship_p2_li2: String
    get() = locales[getCurrentLanguageCode()]?.conf_sponsorship_p2_li2 ?:
        baseLocale.conf_sponsorship_p2_li2

  public val conf_sponsorship_p2_li2_1: String
    get() = locales[getCurrentLanguageCode()]?.conf_sponsorship_p2_li2_1 ?:
        baseLocale.conf_sponsorship_p2_li2_1

  public val conf_sponsorship_p2_li2_2: String
    get() = locales[getCurrentLanguageCode()]?.conf_sponsorship_p2_li2_2 ?:
        baseLocale.conf_sponsorship_p2_li2_2

  public val conf_sponsorship_p2_li2_3: String
    get() = locales[getCurrentLanguageCode()]?.conf_sponsorship_p2_li2_3 ?:
        baseLocale.conf_sponsorship_p2_li2_3

  public val conf_sponsorship_p2_more_about_us_p1: String
    get() = locales[getCurrentLanguageCode()]?.conf_sponsorship_p2_more_about_us_p1 ?:
        baseLocale.conf_sponsorship_p2_more_about_us_p1

  public val conf_sponsorship_p2_more_about_us_p2: String
    get() = locales[getCurrentLanguageCode()]?.conf_sponsorship_p2_more_about_us_p2 ?:
        baseLocale.conf_sponsorship_p2_more_about_us_p2

  public val conf_sponsorship_what_we_offer: String
    get() = locales[getCurrentLanguageCode()]?.conf_sponsorship_what_we_offer ?:
        baseLocale.conf_sponsorship_what_we_offer

  public val conf_sponsorship_p3: String
    get() = locales[getCurrentLanguageCode()]?.conf_sponsorship_p3 ?: baseLocale.conf_sponsorship_p3

  public val conf_sponsorship_p3_li1: String
    get() = locales[getCurrentLanguageCode()]?.conf_sponsorship_p3_li1 ?:
        baseLocale.conf_sponsorship_p3_li1

  public val conf_sponsorship_p3_li2: String
    get() = locales[getCurrentLanguageCode()]?.conf_sponsorship_p3_li2 ?:
        baseLocale.conf_sponsorship_p3_li2

  public val conf_sponsorship_p3_li3: String
    get() = locales[getCurrentLanguageCode()]?.conf_sponsorship_p3_li3 ?:
        baseLocale.conf_sponsorship_p3_li3

  public val conf_sponsorship_p3_li4: String
    get() = locales[getCurrentLanguageCode()]?.conf_sponsorship_p3_li4 ?:
        baseLocale.conf_sponsorship_p3_li4

  public val conf_sponsorship_p4: String
    get() = locales[getCurrentLanguageCode()]?.conf_sponsorship_p4 ?: baseLocale.conf_sponsorship_p4

  public val conf_sponsorship_plan_A: String
    get() = locales[getCurrentLanguageCode()]?.conf_sponsorship_plan_A ?:
        baseLocale.conf_sponsorship_plan_A

  public val conf_sponsorship_plan_B: String
    get() = locales[getCurrentLanguageCode()]?.conf_sponsorship_plan_B ?:
        baseLocale.conf_sponsorship_plan_B

  public val conf_sponsorship_plan_C: String
    get() = locales[getCurrentLanguageCode()]?.conf_sponsorship_plan_C ?:
        baseLocale.conf_sponsorship_plan_C

  public val conf_sponsorship_plan_benefit_header: String
    get() = locales[getCurrentLanguageCode()]?.conf_sponsorship_plan_benefit_header ?:
        baseLocale.conf_sponsorship_plan_benefit_header

  public val conf_sponsorship_plan_benefit_company_logos: String
    get() = locales[getCurrentLanguageCode()]?.conf_sponsorship_plan_benefit_company_logos ?:
        baseLocale.conf_sponsorship_plan_benefit_company_logos

  public val conf_sponsorship_plan_benefit_delivery_merch: String
    get() = locales[getCurrentLanguageCode()]?.conf_sponsorship_plan_benefit_delivery_merch ?:
        baseLocale.conf_sponsorship_plan_benefit_delivery_merch

  public val conf_sponsorship_plan_benefit_mentions: String
    get() = locales[getCurrentLanguageCode()]?.conf_sponsorship_plan_benefit_mentions ?:
        baseLocale.conf_sponsorship_plan_benefit_mentions

  public val conf_sponsorship_plan_benefit_logo_size: String
    get() = locales[getCurrentLanguageCode()]?.conf_sponsorship_plan_benefit_logo_size ?:
        baseLocale.conf_sponsorship_plan_benefit_logo_size

  public val conf_sponsorship_plan_benefit_company_time_slot: String
    get() = locales[getCurrentLanguageCode()]?.conf_sponsorship_plan_benefit_company_time_slot ?:
        baseLocale.conf_sponsorship_plan_benefit_company_time_slot

  public val conf_sponsorship_plan_benefit_banner_at_entrance: String
    get() = locales[getCurrentLanguageCode()]?.conf_sponsorship_plan_benefit_banner_at_entrance ?:
        baseLocale.conf_sponsorship_plan_benefit_banner_at_entrance

  public val conf_sponsorship_plan_benefit_banner_at_auditorium: String
    get() = locales[getCurrentLanguageCode()]?.conf_sponsorship_plan_benefit_banner_at_auditorium ?:
        baseLocale.conf_sponsorship_plan_benefit_banner_at_auditorium

  public val conf_sponsorship_plan_benefit_company_speaker: String
    get() = locales[getCurrentLanguageCode()]?.conf_sponsorship_plan_benefit_company_speaker ?:
        baseLocale.conf_sponsorship_plan_benefit_company_speaker

  public val conf_sponsorship_plan_benefit_company_speaker_disclaimer: String
    get() =
        locales[getCurrentLanguageCode()]?.conf_sponsorship_plan_benefit_company_speaker_disclaimer
        ?: baseLocale.conf_sponsorship_plan_benefit_company_speaker_disclaimer

  public val conf_sponsorship_plan_benefit_logo_physical_merch: String
    get() = locales[getCurrentLanguageCode()]?.conf_sponsorship_plan_benefit_logo_physical_merch ?:
        baseLocale.conf_sponsorship_plan_benefit_logo_physical_merch

  public val conf_sponsorship_plan_benefit_attendance_list: String
    get() = locales[getCurrentLanguageCode()]?.conf_sponsorship_plan_benefit_attendance_list ?:
        baseLocale.conf_sponsorship_plan_benefit_attendance_list

  public val conf_sponsor_i_want_to_sponsor: String
    get() = locales[getCurrentLanguageCode()]?.conf_sponsor_i_want_to_sponsor ?:
        baseLocale.conf_sponsor_i_want_to_sponsor

  public val conf_sponsor_i_want_to_sponsor_instructions: String
    get() = locales[getCurrentLanguageCode()]?.conf_sponsor_i_want_to_sponsor_instructions ?:
        baseLocale.conf_sponsor_i_want_to_sponsor_instructions

  public val conf_sponsor_i_want_to_sponsor_option_1: String
    get() = locales[getCurrentLanguageCode()]?.conf_sponsor_i_want_to_sponsor_option_1 ?:
        baseLocale.conf_sponsor_i_want_to_sponsor_option_1

  public val conf_sponsor_i_want_to_sponsor_option_2: String
    get() = locales[getCurrentLanguageCode()]?.conf_sponsor_i_want_to_sponsor_option_2 ?:
        baseLocale.conf_sponsor_i_want_to_sponsor_option_2

  public val conf_sponsor_i_want_to_sponsor_option_3: String
    get() = locales[getCurrentLanguageCode()]?.conf_sponsor_i_want_to_sponsor_option_3 ?:
        baseLocale.conf_sponsor_i_want_to_sponsor_option_3

  public val conf_sponsor_i_want_to_sponsor_email_us_pt1: String
    get() = locales[getCurrentLanguageCode()]?.conf_sponsor_i_want_to_sponsor_email_us_pt1 ?:
        baseLocale.conf_sponsor_i_want_to_sponsor_email_us_pt1

  public val conf_sponsor_i_want_to_sponsor_email_us_pt2: String
    get() = locales[getCurrentLanguageCode()]?.conf_sponsor_i_want_to_sponsor_email_us_pt2 ?:
        baseLocale.conf_sponsor_i_want_to_sponsor_email_us_pt2

  public val conf_sponsor_i_want_to_sponsor_fill_form_pt1: String
    get() = locales[getCurrentLanguageCode()]?.conf_sponsor_i_want_to_sponsor_fill_form_pt1 ?:
        baseLocale.conf_sponsor_i_want_to_sponsor_fill_form_pt1

  public val conf_sponsor_i_want_to_sponsor_fill_form_pt2: String
    get() = locales[getCurrentLanguageCode()]?.conf_sponsor_i_want_to_sponsor_fill_form_pt2 ?:
        baseLocale.conf_sponsor_i_want_to_sponsor_fill_form_pt2

  public val conf_sponsor_i_want_to_sponsor_schedule_meeting_pt1: String
    get() = locales[getCurrentLanguageCode()]?.conf_sponsor_i_want_to_sponsor_schedule_meeting_pt1
        ?: baseLocale.conf_sponsor_i_want_to_sponsor_schedule_meeting_pt1

  public val conf_sponsor_i_want_to_sponsor_schedule_meeting_pt2: String
    get() = locales[getCurrentLanguageCode()]?.conf_sponsor_i_want_to_sponsor_schedule_meeting_pt2
        ?: baseLocale.conf_sponsor_i_want_to_sponsor_schedule_meeting_pt2

  public val conf_coc_intro_title: String
    get() = locales[getCurrentLanguageCode()]?.conf_coc_intro_title ?:
        baseLocale.conf_coc_intro_title

  public val conf_coc_intro_p1: String
    get() = locales[getCurrentLanguageCode()]?.conf_coc_intro_p1 ?: baseLocale.conf_coc_intro_p1

  public val conf_coc_intro_p2: String
    get() = locales[getCurrentLanguageCode()]?.conf_coc_intro_p2 ?: baseLocale.conf_coc_intro_p2

  public val conf_coc_purpose_title: String
    get() = locales[getCurrentLanguageCode()]?.conf_coc_purpose_title ?:
        baseLocale.conf_coc_purpose_title

  public val conf_coc_purpose_p1: String
    get() = locales[getCurrentLanguageCode()]?.conf_coc_purpose_p1 ?: baseLocale.conf_coc_purpose_p1

  public val conf_coc_purpose_p2: String
    get() = locales[getCurrentLanguageCode()]?.conf_coc_purpose_p2 ?: baseLocale.conf_coc_purpose_p2

  public val conf_coc_purpose_p3: String
    get() = locales[getCurrentLanguageCode()]?.conf_coc_purpose_p3 ?: baseLocale.conf_coc_purpose_p3

  public val conf_coc_report_title: String
    get() = locales[getCurrentLanguageCode()]?.conf_coc_report_title ?:
        baseLocale.conf_coc_report_title

  public val conf_coc_report_p1: String
    get() = locales[getCurrentLanguageCode()]?.conf_coc_report_p1 ?: baseLocale.conf_coc_report_p1

  public val conf_coc_report_card_1: String
    get() = locales[getCurrentLanguageCode()]?.conf_coc_report_card_1 ?:
        baseLocale.conf_coc_report_card_1

  public val conf_coc_report_card_2: String
    get() = locales[getCurrentLanguageCode()]?.conf_coc_report_card_2 ?:
        baseLocale.conf_coc_report_card_2

  public val conf_coc_report_card_3: String
    get() = locales[getCurrentLanguageCode()]?.conf_coc_report_card_3 ?:
        baseLocale.conf_coc_report_card_3

  public val conf_coc_compliance_title: String
    get() = locales[getCurrentLanguageCode()]?.conf_coc_compliance_title ?:
        baseLocale.conf_coc_compliance_title

  public val conf_coc_compliance_p1: String
    get() = locales[getCurrentLanguageCode()]?.conf_coc_compliance_p1 ?:
        baseLocale.conf_coc_compliance_p1

  public val conf_coc_compliance_p2: String
    get() = locales[getCurrentLanguageCode()]?.conf_coc_compliance_p2 ?:
        baseLocale.conf_coc_compliance_p2

  public val conf_coc_compliance_p3: String
    get() = locales[getCurrentLanguageCode()]?.conf_coc_compliance_p3 ?:
        baseLocale.conf_coc_compliance_p3

  public val conf_coc_harassment_title: String
    get() = locales[getCurrentLanguageCode()]?.conf_coc_harassment_title ?:
        baseLocale.conf_coc_harassment_title

  public val conf_coc_harassment_p1: String
    get() = locales[getCurrentLanguageCode()]?.conf_coc_harassment_p1 ?:
        baseLocale.conf_coc_harassment_p1

  public val conf_coc_harassment_p2: String
    get() = locales[getCurrentLanguageCode()]?.conf_coc_harassment_p2 ?:
        baseLocale.conf_coc_harassment_p2

  public val conf_coc_harassment_p3: String
    get() = locales[getCurrentLanguageCode()]?.conf_coc_harassment_p3 ?:
        baseLocale.conf_coc_harassment_p3

  public val conf_coc_harassment_p4: String
    get() = locales[getCurrentLanguageCode()]?.conf_coc_harassment_p4 ?:
        baseLocale.conf_coc_harassment_p4

  public val conf_coc_harassment_p5: String
    get() = locales[getCurrentLanguageCode()]?.conf_coc_harassment_p5 ?:
        baseLocale.conf_coc_harassment_p5

  public val conf_coc_harassment_p6: String
    get() = locales[getCurrentLanguageCode()]?.conf_coc_harassment_p6 ?:
        baseLocale.conf_coc_harassment_p6

  public val conf_coc_harassment_p7: String
    get() = locales[getCurrentLanguageCode()]?.conf_coc_harassment_p7 ?:
        baseLocale.conf_coc_harassment_p7

  public val conf_coc_harassment_p8: String
    get() = locales[getCurrentLanguageCode()]?.conf_coc_harassment_p8 ?:
        baseLocale.conf_coc_harassment_p8

  public val conf_coc_discrimination_title: String
    get() = locales[getCurrentLanguageCode()]?.conf_coc_discrimination_title ?:
        baseLocale.conf_coc_discrimination_title

  public val conf_coc_discrimination_p1: String
    get() = locales[getCurrentLanguageCode()]?.conf_coc_discrimination_p1 ?:
        baseLocale.conf_coc_discrimination_p1

  public val conf_coc_need_help_title: String
    get() = locales[getCurrentLanguageCode()]?.conf_coc_need_help_title ?:
        baseLocale.conf_coc_need_help_title

  public val conf_coc_need_help_p1: String
    get() = locales[getCurrentLanguageCode()]?.conf_coc_need_help_p1 ?:
        baseLocale.conf_coc_need_help_p1

  public val conf_coc_need_help_p2: String
    get() = locales[getCurrentLanguageCode()]?.conf_coc_need_help_p2 ?:
        baseLocale.conf_coc_need_help_p2

  public val conf_coc_need_help_p3: String
    get() = locales[getCurrentLanguageCode()]?.conf_coc_need_help_p3 ?:
        baseLocale.conf_coc_need_help_p3

  public val privacy_policy: String
    get() = locales[getCurrentLanguageCode()]?.privacy_policy ?: baseLocale.privacy_policy

  public val privacy_policy_p1: String
    get() = locales[getCurrentLanguageCode()]?.privacy_policy_p1 ?: baseLocale.privacy_policy_p1

  public val privacy_policy_p2: String
    get() = locales[getCurrentLanguageCode()]?.privacy_policy_p2 ?: baseLocale.privacy_policy_p2

  public val privacy_policy_website: String
    get() = locales[getCurrentLanguageCode()]?.privacy_policy_website ?:
        baseLocale.privacy_policy_website

  public val privacy_policy_website_p1: String
    get() = locales[getCurrentLanguageCode()]?.privacy_policy_website_p1 ?:
        baseLocale.privacy_policy_website_p1

  public val privacy_policy_attendees: String
    get() = locales[getCurrentLanguageCode()]?.privacy_policy_attendees ?:
        baseLocale.privacy_policy_attendees

  public val privacy_policy_attendees_p1: String
    get() = locales[getCurrentLanguageCode()]?.privacy_policy_attendees_p1 ?:
        baseLocale.privacy_policy_attendees_p1

  public val privacy_policy_attendees_p2: String
    get() = locales[getCurrentLanguageCode()]?.privacy_policy_attendees_p2 ?:
        baseLocale.privacy_policy_attendees_p2

  public val privacy_policy_attendees_p3: String
    get() = locales[getCurrentLanguageCode()]?.privacy_policy_attendees_p3 ?:
        baseLocale.privacy_policy_attendees_p3

  public val privacy_policy_attendees_p4: String
    get() = locales[getCurrentLanguageCode()]?.privacy_policy_attendees_p4 ?:
        baseLocale.privacy_policy_attendees_p4

  public val privacy_policy_attendees_p5: String
    get() = locales[getCurrentLanguageCode()]?.privacy_policy_attendees_p5 ?:
        baseLocale.privacy_policy_attendees_p5

  public val privacy_policy_attendees_p6_pt1: String
    get() = locales[getCurrentLanguageCode()]?.privacy_policy_attendees_p6_pt1 ?:
        baseLocale.privacy_policy_attendees_p6_pt1

  public val privacy_policy_attendees_p6_pt2: String
    get() = locales[getCurrentLanguageCode()]?.privacy_policy_attendees_p6_pt2 ?:
        baseLocale.privacy_policy_attendees_p6_pt2

  public val privacy_policy_speakers: String
    get() = locales[getCurrentLanguageCode()]?.privacy_policy_speakers ?:
        baseLocale.privacy_policy_speakers

  public val privacy_policy_speakers_p1: String
    get() = locales[getCurrentLanguageCode()]?.privacy_policy_speakers_p1 ?:
        baseLocale.privacy_policy_speakers_p1

  public val privacy_policy_speakers_p2: String
    get() = locales[getCurrentLanguageCode()]?.privacy_policy_speakers_p2 ?:
        baseLocale.privacy_policy_speakers_p2

  public val privacy_policy_speakers_p3: String
    get() = locales[getCurrentLanguageCode()]?.privacy_policy_speakers_p3 ?:
        baseLocale.privacy_policy_speakers_p3

  public val privacy_policy_speakers_p4: String
    get() = locales[getCurrentLanguageCode()]?.privacy_policy_speakers_p4 ?:
        baseLocale.privacy_policy_speakers_p4

  public val privacy_policy_speakers_p5_pt1: String
    get() = locales[getCurrentLanguageCode()]?.privacy_policy_speakers_p5_pt1 ?:
        baseLocale.privacy_policy_speakers_p5_pt1

  public val privacy_policy_speakers_p5_pt2: String
    get() = locales[getCurrentLanguageCode()]?.privacy_policy_speakers_p5_pt2 ?:
        baseLocale.privacy_policy_speakers_p5_pt2

  public val privacy_policy_speakers_p5_pt3: String
    get() = locales[getCurrentLanguageCode()]?.privacy_policy_speakers_p5_pt3 ?:
        baseLocale.privacy_policy_speakers_p5_pt3

  public val privacy_policy_speakers_p6: String
    get() = locales[getCurrentLanguageCode()]?.privacy_policy_speakers_p6 ?:
        baseLocale.privacy_policy_speakers_p6

  public val privacy_policy_sponsors: String
    get() = locales[getCurrentLanguageCode()]?.privacy_policy_sponsors ?:
        baseLocale.privacy_policy_sponsors

  public val privacy_policy_sponsors_p1: String
    get() = locales[getCurrentLanguageCode()]?.privacy_policy_sponsors_p1 ?:
        baseLocale.privacy_policy_sponsors_p1

  public val privacy_policy_sponsors_p2: String
    get() = locales[getCurrentLanguageCode()]?.privacy_policy_sponsors_p2 ?:
        baseLocale.privacy_policy_sponsors_p2

  public val privacy_policy_sponsors_p3_pt1: String
    get() = locales[getCurrentLanguageCode()]?.privacy_policy_sponsors_p3_pt1 ?:
        baseLocale.privacy_policy_sponsors_p3_pt1

  public val privacy_policy_sponsors_p3_pt2: String
    get() = locales[getCurrentLanguageCode()]?.privacy_policy_sponsors_p3_pt2 ?:
        baseLocale.privacy_policy_sponsors_p3_pt2

  public val privacy_policy_sponsors_p4_pt1: String
    get() = locales[getCurrentLanguageCode()]?.privacy_policy_sponsors_p4_pt1 ?:
        baseLocale.privacy_policy_sponsors_p4_pt1

  public val privacy_policy_sponsors_p4_pt2: String
    get() = locales[getCurrentLanguageCode()]?.privacy_policy_sponsors_p4_pt2 ?:
        baseLocale.privacy_policy_sponsors_p4_pt2

  public val privacy_policy_rights: String
    get() = locales[getCurrentLanguageCode()]?.privacy_policy_rights ?:
        baseLocale.privacy_policy_rights

  public val privacy_policy_complaints: String
    get() = locales[getCurrentLanguageCode()]?.privacy_policy_complaints ?:
        baseLocale.privacy_policy_complaints

  public val privacy_policy_complaints_p1: String
    get() = locales[getCurrentLanguageCode()]?.privacy_policy_complaints_p1 ?:
        baseLocale.privacy_policy_complaints_p1

  public val privacy_policy_access_your_data: String
    get() = locales[getCurrentLanguageCode()]?.privacy_policy_access_your_data ?:
        baseLocale.privacy_policy_access_your_data

  public val privacy_policy_access_your_data_p1: String
    get() = locales[getCurrentLanguageCode()]?.privacy_policy_access_your_data_p1 ?:
        baseLocale.privacy_policy_access_your_data_p1

  public val privacy_policy_access_your_data_p2: String
    get() = locales[getCurrentLanguageCode()]?.privacy_policy_access_your_data_p2 ?:
        baseLocale.privacy_policy_access_your_data_p2

  public val privacy_policy_access_your_data_p3: String
    get() = locales[getCurrentLanguageCode()]?.privacy_policy_access_your_data_p3 ?:
        baseLocale.privacy_policy_access_your_data_p3

  public val privacy_policy_access_your_data_p4: String
    get() = locales[getCurrentLanguageCode()]?.privacy_policy_access_your_data_p4 ?:
        baseLocale.privacy_policy_access_your_data_p4
}
