package strings

import io.github.skeptick.libres.strings.PluralForms
import io.github.skeptick.libres.strings.getCurrentLanguageCode
import kotlin.String

public object StringsEn : Strings {
  override val locale: String = "en"

  override val language_option_es: String = "🇵🇪 Spanish"

  override val language_option_en: String = "🇺🇸 English"

  override val site_metatag_title: String = "Android Dev Peru"

  override val site_metatag_description: String =
      "Site of the Android Dev Peru community. Here you will find events, resources, and other news about our Android developer community."

  override val site_navlink_about_us: String = "About Us"

  override val site_navlink_resources: String = "Resources"

  override val site_navlink_conference: String = "Conference"

  override val site_navlink_subscribe: String = "Subscribe"

  override val home_header_title: String = "Android Dev Peru"

  override val home_header_caption: String = "Android & Kotlin Community in Peru and LATAM"

  override val home_quick_links_wsp: String = "Join on WhatsApp"

  override val home_quick_links_talk: String = "Submit a Talk"

  override val home_quick_links_linkedin: String = "Follow us on LinkedIn"

  override val home_highlights_card_title: String = "Featured Content"

  override val home_highlights_card_kotlin_conf_title: String = "Kotlin Conf"

  override val home_highlights_card_kotlin_conf_description: String =
      "Last June we gathered in person for the Kotlin Conf - Lima.<br><br>🗓 Date: Wednesday, June 26th<br>⏰ Time: 7PM 🇵🇪 🇨🇴<br>🏢 Location: NTT Data<br>"

  override val home_highlights_card_kotlin_conf_cta: String = "Watch Recording"

  override val home_highlights_card_salary_2023_title: String = "Salary Survey 2023"

  override val home_highlights_card_salary_2023_description: String =
      "Transparency can help reduce salary gaps. Last year, we conducted anonymous surveys and received +100 responses from mobile devs in LATAM."

  override val home_highlights_card_salary_2023_cta: String = "See Results"

  override val home_save_the_date: String = "Saturday, October 19th"

  override val home_save_the_date_location: String = "Lima, Peru 🇵🇪"

  override val about_us_title: String = "Android Developer Community"

  override val about_us_description: String =
      "We are Peru's largest and most active Android and Kotlin community. Over the years, we have organized dozens of free in-person and virtual events.<br><br>We invite you to participate as an attendee or speaker at our meetups, workshops, and conferences.<br><br>"

  override val about_organizers_title: String = "Organizing Team"

  override val about_organizers_caption: String =
      "They are the ones responsible for keeping the community active month after month."

  override val about_ex_organizers_title: String = "Legacy Team"

  override val about_ex_organizers_caption: String =
      "Although they are no longer with us on the team, we greatly appreciate the support of our retired organizers ❤️"

  override val resources_blog_posts_title: String = "Recent Articles"

  override val resources_blog_posts_description: String =
      "Did you miss an event? No problem - catch up on the latest news from our community\""

  override val resources_blog_posts_cta: String = "View all articles"

  override val resources_blog_posts_caption: String = "Articles"

  override val resources_blog_posts_meetup_57: String =
      "Meetup #57: Kotlin Multiplatform is Stable!"

  override val resources_blog_posts_meetup_57_desc: String =
      "\"On November 26th, 2023, GDE Yury Camacho from Bolivia visited us to talk about Kotlin Multiplatform.\""

  override val resources_blog_posts_devfest_2023: String = "DevFest 2023 - GDG Open"

  override val resources_blog_posts_devfest_2023_desc: String =
      "On November 12, 2023, we participated in the GDG Open DevFest 2023, where we conducted a workshop on Jetpack Compose."

  override val resources_blog_posts_bcp: String = "BCP Community Fair"

  override val resources_blog_posts_bcp_desc: String =
      "On November 22, we had the opportunity to join the community fair organized by BCP, where more than 400 people participated."

  override val resources_blog_posts_meetup_56: String = "Meetup #56: Bluetooth from Scratch"

  override val resources_blog_posts_meetup_56_desc: String =
      "On October 30, we had a virtual talk! Hansy Schmitt shared his knowledge of Bluetooth."

  override val resources_playlists_title: String = "Playlists"

  override val resources_playlists_description: String =
      "Don't miss the exclusive material that the community has shared with us over the years."

  override val resources_playlists_cta: String = "Follow us on YouTube"

  override val resources_playlists_caption: String = "Playlist"

  override val resources_playlists_card_meetups: String = "[LIVE] Meetups"

  override val resources_playlists_card_meetups_desc: String =
      "Missed a meetup? No worries. Here's the recordings. (in Spanish)"

  override val resources_playlists_card_android_workshop: String = "From Zero to Android"

  override val resources_playlists_card_android_workshop_desc: String =
      "Free Workshop to learn about Android from scratch. (in Spanish)"

  override val main_footer_subscribe: String = "Subscribe"

  override val main_footer_this_web_is: String = "This web is "

  override val main_footer_open_source: String = "open source ♥"

  override val conf_title: String = "Android Dev Peru Conf"

  override val conf_meta_title: String = "Android Dev Peru Conf 2024"

  override val conf_meta_description: String =
      "Enjoy a day full of talks with international speakers passionate about this Android. And, as all of our events - it's free! 🎉"

  override val conf_navlink_c4p: String = "Call for Speakers"

  override val conf_navlink_sponsor: String = "Become a sponsor"

  override val conf_navlink_about_us: String = "About us"

  override val conf_navlink_register: String = "Register"

  override val conf_navlink_code_of_conduct: String = "Code of Conduct"

  override val conf_header_time: String = "October 19th. 8am-2pm."

  override val conf_header_location: String = "Lima, UPC Monterrico."

  override val conf_header_caption: String =
      "Let's celebrate 10 years of community, and the arrival of Android 16 🎉"

  override val conf_events_title: String = "About the event"

  override val conf_events_celebrate: String =
      "We're celebrating 10 years sharing our love for Android with the community."

  override val conf_events_enjoy: String =
      "Enjoy a day full of talks with international speakers passionate about this technology. And, as all of our events - "

  override val conf_events_free: String = "¡it's free! 🎉"

  override val conf_events_registration_soon: String =
      "Registration is not open yet, but if you want us to keep you updated... "

  override val conf_events_register: String = "Subscribe to our newsletter"

  override val conf_c4p_title: String = "Call for Speakers"

  override val conf_c4p_caption: String =
      "We are looking for talks about Android development, Kotlin, multiplatform, Gradle, CI/CD, AI/ML, Firebase and more. Don't hesitate to send us your proposal! 🎤Call for Speakers"

  override val conf_c4p_cta: String = "Submit talk"

  override val conf_location_title: String = "Location"

  override val conf_location_details: String =
      "🏢 Venue: UPC Monterrico Campus<br>🏟️ Bancalari Auditorium - Pavilion H, first floor<br>🧭 Address: Prolongación Primavera 2390, Santiago de Surco<br><br>⏰ Entry time: 8am"

  override val conf_location_cta: String = "Open in Maps"

  override val conf_location_map_alt: String =
      "Entering to the left, go down the stair and you'll be in the Pavilion H entrance"

  override val conf_sponsor_title: String = "Become a sponsor"

  override val conf_sponsor_caption: String =
      "This event would not be possible without your help. Would you like to support the community and introduce your company to our entire audience?"

  override val conf_sponsor_im_interested: String = "I'm interested"

  override val conf_sponsorship_title: String = "Interested in Sponsoring?"

  override val conf_sponsorship_p1: String =
      "At this Android Dev Peru Conf, we will celebrate the contributions of our community and highlight the companies, individuals, and organizations that drive Android development in Peru."

  override val conf_sponsorship_p2: String =
      "We want to make this event a great success, building on our 10 years of experience working with the community, during which we have:"

  override val conf_sponsorship_p2_li1: String = "Organized dozens of events:"

  override val conf_sponsorship_p2_li1_1: String =
      "+40 in-person meetups (average attendance: 30 people)"

  override val conf_sponsorship_p2_li1_2: String =
      "+20 virtual meetups (average attendance: 50 people)"

  override val conf_sponsorship_p2_li1_3: String =
      "3 in-person and virtual workshops (average attendance: 20 people)"

  override val conf_sponsorship_p2_li1_4: String = "Kotlin Everywhere 2019 (attendance: 80 people)"

  override val conf_sponsorship_p2_li2: String = "Expanded our online presence:"

  override val conf_sponsorship_p2_li2_1: String = "WhatsApp: +400 active members"

  override val conf_sponsorship_p2_li2_2: String = "Linkedin: +850 followers"

  override val conf_sponsorship_p2_li2_3: String = "Youtube: +850 subscribers"

  override val conf_sponsorship_p2_more_about_us_p1: String =
      "You can read more about the community and the organizers "

  override val conf_sponsorship_p2_more_about_us_p2: String = "by visiting this link."

  override val conf_sponsorship_what_we_offer: String = "What do we offer?"

  override val conf_sponsorship_p3: String =
      "In this Android Dev Peru Conf 2024 edition, we expect:"

  override val conf_sponsorship_p3_li1: String = "100-140 in-person attendees"

  override val conf_sponsorship_p3_li2: String = "50-100 virtual attendees (from all over LATAM)"

  override val conf_sponsorship_p3_li3: String =
      "4 talks by expert speakers, both national and international"

  override val conf_sponsorship_p3_li4: String =
      "Hundreds of interactions across all our social media"

  override val conf_sponsorship_p4: String =
      "As a sponsor, we will offer you different spaces to showcase your company to our entire audience. We offer various support plans to best suit your company's needs."

  override val conf_sponsorship_plan_A: String = "🥇 Gold"

  override val conf_sponsorship_plan_B: String = "🥈 Silver"

  override val conf_sponsorship_plan_C: String = "🥉 Bronze"

  override val conf_sponsorship_plan_benefit_header: String = "Benefits per sponsorship plan"

  override val conf_sponsorship_plan_benefit_company_logos: String =
      "Company logos across all digital channels."

  override val conf_sponsorship_plan_benefit_delivery_merch: String =
      "Delivery of merchandising (pens, stickers, shirt, brochures) provided by the company."

  override val conf_sponsorship_plan_benefit_mentions: String =
      "Mention of the company at the opening and closing ceremonies."

  override val conf_sponsorship_plan_benefit_logo_size: String = "Logo size on our networks."

  override val conf_sponsorship_plan_benefit_company_time_slot: String =
      "Time slot for a brief company presentation"

  override val conf_sponsorship_plan_benefit_banner_at_entrance: String =
      "Space for a banner (1x2m) at the entrance of the auditorium."

  override val conf_sponsorship_plan_benefit_banner_at_auditorium: String =
      "Space for banner (1x2m) in the main auditorium of the event."

  override val conf_sponsorship_plan_benefit_company_speaker: String =
      "20-min speaker slot for your chosen speaker (only if the talk is deeply Android-related)"

  override val conf_sponsorship_plan_benefit_company_speaker_disclaimer: String =
      "* Subject to availability of agenda. Priority will be given to sponsors who confirm first."

  override val conf_sponsorship_plan_benefit_logo_physical_merch: String =
      "Uso del logo de la empresa en polos (speakers, organizadores y premios)."

  override val conf_sponsorship_plan_benefit_attendance_list: String =
      "Excel con información de las personas registrados al evento, previo consentimiento. (para publicidad y ofertas laborales)."

  override val conf_sponsor_i_want_to_sponsor: String = "I want to be an sponsor"

  override val conf_sponsor_i_want_to_sponsor_instructions: String =
      "Let's continue the conversation in the format that suits you the most: "

  override val conf_sponsor_i_want_to_sponsor_option_1: String = "Option 1: "

  override val conf_sponsor_i_want_to_sponsor_option_2: String = "Option 2: "

  override val conf_sponsor_i_want_to_sponsor_option_3: String = "Option 3: "

  override val conf_sponsor_i_want_to_sponsor_email_us_pt1: String = "Email us at "

  override val conf_sponsor_i_want_to_sponsor_email_us_pt2: String =
      " indicating your company and the plan you are interested in."

  override val conf_sponsor_i_want_to_sponsor_fill_form_pt1: String = "Complete this form"

  override val conf_sponsor_i_want_to_sponsor_fill_form_pt2: String =
      " and we will get in touch with you."

  override val conf_sponsor_i_want_to_sponsor_schedule_meeting_pt1: String =
      "Schedule a meeting with us "

  override val conf_sponsor_i_want_to_sponsor_schedule_meeting_pt2: String =
      " and we will clarify any doubts you may have."

  override val conf_coc_intro_title: String = "Code of Conduct"

  override val conf_coc_intro_p1: String =
      "Android Dev Peru seeks to provide a harassment-free conference experience for everyone."

  override val conf_coc_intro_p2: String =
      "Anyone who attends (including speakers, sponsors, and volunteers) must respect our Code of Conduct. The organizing team will not tolerate any form of harassment or discrimination; anyone who commits these actions will be sanctioned. The organizers will enforce this code throughout the event. We expect the cooperation of all participants to help ensure a safe environment for everyone. Help us make Android Dev Peru Conf 24 a pleasant experience for everyone!"

  override val conf_coc_purpose_title: String = "Purpose of this document"

  override val conf_coc_purpose_p1: String =
      "In this conference, we want to provide a harassment-free experience for all those who attend, regardless of their gender, sexual orientation, disability, physical appearance, body type, race, or religion. We will not tolerate harassment of conference participants in any form. Sexual language and images are not appropriate at any time during the conference; including talks, workshops, parties, Facebook, X (aka Twitter) and any other social network."

  override val conf_coc_purpose_p2: String =
      "Conference participants who violate these rules may be expelled from the event at the discretion of the event organizers."

  override val conf_coc_purpose_p3: String =
      "Sponsors are also subject to our anti-harassment policy. These in particular, may not use sexualized images, activities, or material. Booth staff, including volunteers, may not use clothing/uniforms/costumes or anything else that creates a sexualized environment."

  override val conf_coc_report_title: String = "Reports"

  override val conf_coc_report_p1: String =
      "If someone makes you or someone else feel unsafe or inappropriate, please report it as soon as possible. If you see or hear someone doing something that violates this code of conduct, please let us know. You can make your report by any of the following means:"

  override val conf_coc_report_card_1: String =
      "Contact a staff member, you can identify us by our shirts with the word \"organizer\"."

  override val conf_coc_report_card_2: String =
      "Direct messages on Twitter: our direct messages on Twitter are always open."

  override val conf_coc_report_card_3: String =
      "Email: You can send us an email, we will take approximately one hour to respond."

  override val conf_coc_compliance_title: String = "Compliance"

  override val conf_coc_compliance_p1: String =
      "Participants who are asked to stop any harassing behavior are expected to do so immediately."

  override val conf_coc_compliance_p2: String =
      "Anyone who engages in harassing behavior the event organizers reserve the right to take any necessary measures to maintain the event with a welcoming environment for all participants. This includes warnings or expulsion from the conference."

  override val conf_coc_compliance_p3: String =
      "We expect all participants to follow these rules during their stay at the conference, workshops, and any social event related to the conference."

  override val conf_coc_harassment_title: String = "Harassment"

  override val conf_coc_harassment_p1: String =
      "Verbal or written comments that reinforce social structures of domination related to gender, gender identity and expression, sexual orientation, disability, physical appearance, body type, race, age, or religion."

  override val conf_coc_harassment_p2: String = "Sexual images in public spaces."

  override val conf_coc_harassment_p3: String = "Deliberate intimidation, stalking, or harassment."

  override val conf_coc_harassment_p4: String = "Harassment involving photographs or videos."

  override val conf_coc_harassment_p5: String =
      "Constant interruptions during conversations or other events."

  override val conf_coc_harassment_p6: String = "Inappropriate physical contact."

  override val conf_coc_harassment_p7: String = "Unwanted sexual attention."

  override val conf_coc_harassment_p8: String =
      "Advocating for or encouraging any of the aforementioned behaviors."

  override val conf_coc_discrimination_title: String = "Disability Discrimination"

  override val conf_coc_discrimination_p1: String =
      "Words like \"crazy,\" \"insane,\" \"mongoloid,\" or \"mentally retarded\" are examples of ableist language that devalues people with physical or mental disabilities. Often, this does not stem from an intentional desire to offend, but from the normalization of what it means to be \"normal.\" These words can be avoided by using more appropriate and clear descriptions of what you want to communicate. For more information about disability discrimination and replacement terms, please read this guide."

  override val conf_coc_need_help_title: String = "Need Help?"

  override val conf_coc_need_help_p1: String =
      "Our staff is happy to help participants feel safe during the conference. Contact us! ↓"

  override val conf_coc_need_help_p2: String = "Twitter: "

  override val conf_coc_need_help_p3: String = "Email: "

  override val privacy_policy: String = "Privacy Policy"

  override val privacy_policy_p1: String =
      "Android Dev Peru is non-for-profit community. The data we collect won't be used for commercial or lucrative purposes."

  override val privacy_policy_p2: String =
      "This privacy notice tells you what to expect when we collect personal information. It applies to information we collect about:"

  override val privacy_policy_website: String = "Visitors to our websites"

  override val privacy_policy_website_p1: String =
      "When someone visits one of our websites, we store web server log information, which includes information such as the IP address you connected from, the user agent of your browser and which page you requested. We use this information to monitor the security, performance and health of our systems. All of our websites are protected by TLS. This means your connection to our websites is encrypted."

  override val privacy_policy_attendees: String = "People who register and attend our events"

  override val privacy_policy_attendees_p1: String =
      "If you have registered for our events, we store the personal information about you, which was either provided by you or someone from your organization who gave us permission to provide us this information."

  override val privacy_policy_attendees_p2: String =
      "This information includes your full name, email address, location, and government ID, which we require to grant you access to the conference. Additional information associated with your ticket may be requested but not required. This includes job title, company name, among others. We ask for the additional optional information because it helps us better understand the profile of our attendees and ensure the high quality of the overall event experience is relevant to our audience. For example, we ask for your job title to confirm that we include content in the program that is relevant to your job role. We may also ask for your dietary restrictions and preferences to ensure we order appropriate food in the right quantities. We may also use your contact information including email address and phone number to contact you with important information related to the event or in case of emergencies."

  override val privacy_policy_attendees_p3: String =
      "Leading up to the event, you may receive emails that include practical information related to the event including registration hours, links to additional social events and new speaker announcements. After the event, you may receive an email requesting event feedback and including links to watch event videos online. Emails both before and after the event may also contain information about upcoming free community events and other information relevant for attendees."

  override val privacy_policy_attendees_p4: String =
      "Each of our marketing communications provides you with the options to unsubscribe or alter your communication preferences. You do have the option to unsubscribe from the practical information emails but we strongly encourage you to keep this subscription so we can email you with important practical information for you as an attendee of the event."

  override val privacy_policy_attendees_p5: String =
      "During the event, your personal information will be temporarily stored on our on-site registration and printed out so that the security personnel can grant you access to the event."

  override val privacy_policy_attendees_p6_pt1: String =
      "Your picture may be taken during the event and published on our social and marketing channels. Please make yourself known to the organizing team if you do not wish to have your picture taken during the event. If you find an unwanted picture of yourself on any of our channels, please email us at "

  override val privacy_policy_attendees_p6_pt2: String =
      " with the link to the photo and we will take it down."

  override val privacy_policy_speakers: String = "People who are speaking at one of our events"

  override val privacy_policy_speakers_p1: String =
      "When you are speaking at one of our events, we do need some personal information in order to communicate with you, arrange travel and accommodation, or ensure other practical details related to your appearance at our event."

  override val privacy_policy_speakers_p2: String =
      "This information includes your full name, email, phone number and country of residence. We may share this information with third parties when it is required for fulfilling our agreement with you. For instance, we may need to share your name and email with a hotel to book a room for you during the event. We also need some information in order to publish and market your talk on our website. This information includes your name, company name, profile picture, speaker bio, social media profile links, links to your blog(s) or website(s) as well as the title and description for the talk(s) and/or workshop(s) you will perform at our event."

  override val privacy_policy_speakers_p3: String =
      "During the event, attendees have the ability to submit feedback in the form of ratings, comments and questions about your talk through surveys. This feedback will be monitored by our team members during the event, and the questions during your talk will be relayed to you in front of the audience at the end of the talk if there is time."

  override val privacy_policy_speakers_p4: String =
      "We use this information to gather general feedback about the event, review the quality of the topics and talks, and use as input for planning of future conferences. We do not share this information with any third parties."

  override val privacy_policy_speakers_p5_pt1: String =
      "If logistics allow, we may also record your talk at the conference and collect your slides to publish on our website. We may also publish the recorded video of your talk on "

  override val privacy_policy_speakers_p5_pt2: String = "our YouTube channel"

  override val privacy_policy_speakers_p5_pt3: String =
      ". We may also promote the video of your talk through various social media channels."

  override val privacy_policy_speakers_p6: String =
      "Your picture may be taken during the event and published on our social and marketing channels. Please make yourself known to the organizing team if you do not wish to have your picture taken during the event. If you find an unwanted picture of yourself on our channels, please email us at android@devperu.org with a link to the image and we will take it down."

  override val privacy_policy_sponsors: String = "People who are partners or sponsors"

  override val privacy_policy_sponsors_p1: String =
      "When you sign on as a partner or sponsor of our events, we collect information in the form of billing details to issue your invoice as per the terms of the partnership agreement. This information includes name, email, company name, company address, phone number and RUC. In cases where we don't receive payment, we may send you payment reminders or reach out to assist with finalising the partnership deal."

  override val privacy_policy_sponsors_p2: String =
      "We also collect the names, emails and phone numbers of the contact person/s representing our partners (such as the event manager). This is so we can fulfill our obligations detailed in your partnership agreement and keep you informed about key dates and deliverables."

  override val privacy_policy_sponsors_p3_pt1: String =
      "If your partnership deal contains event tickets or exhibitor passes, you can refer to the "

  override val privacy_policy_sponsors_p3_pt2: String =
      " section for specific information related to registration."

  override val privacy_policy_sponsors_p4_pt1: String =
      "After the conference, we will reach out to you to ask for additional, optional feedback and/or a testimonial, along with the details of upcoming events. You can let us know anytime if you want to opt out of these communications. For more information, you can refer to the "

  override val privacy_policy_sponsors_p4_pt2: String = " section of this policy."

  override val privacy_policy_rights: String = "Your Rights"

  override val privacy_policy_complaints: String = "Complaints or queries"

  override val privacy_policy_complaints_p1: String =
      "We are committed to using best practices to ensure our responsibility when collecting and using personal information, and we take any complaints related to data privacy very seriously. If you think our collection and use of data is unfair, misleading or inappropriate, we encourage you to contact us using the contact information provided at the top of the page."

  override val privacy_policy_access_your_data: String = "Access to your data"

  override val privacy_policy_access_your_data_p1: String =
      "You have a right to know which information we store and process about you, where it came from, what we use it for and who we share it with."

  override val privacy_policy_access_your_data_p2: String =
      "You have a right to have incorrect or incomplete information about you corrected or deleted."

  override val privacy_policy_access_your_data_p3: String =
      "You can exercise these rights by contacting us, and we will do what we can to satisfy your request in a timely manner. For your protection, we may need to verify your identity before responding to your request, such as verifying that the email address from which you send the request matches your email address that we have on file."

  override val privacy_policy_access_your_data_p4: String =
      "In some cases we may not be able to delete your information if we have another legitimate reason preventing us from doing so, such as the obligation to keep financial records for a certain period of time. Please contact us with any questions or concerns about this."
}
